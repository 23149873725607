export const Product = {
    state: () => ({
        sliderMain: {
            id: '1',
            img: 'https://lapshino-api.shop/lapshinkoServ/png/slider/GoodsBig.webp',
        },
        sliderSub: [
            {
                id: '2',
                img: 'https://lapshino-api.shop/lapshinkoServ/png/slider/756248931814435.jpg'
            },
            {
                id: '3',
                img: 'https://lapshino-api.shop/lapshinkoServ/png/slider/recept61507n7i.jpg'
            },
            {
                id: '4',
                img: 'https://lapshino-api.shop/lapshinkoServ/png/slider/d3dc3c.jpg'
            }
        ]
    }),
    namespaced: true
}